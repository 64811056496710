import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Link from 'next/link';

import { toFooterSettingsShape } from 'lib/util/footerSettingsShape';
import { ReduxState } from 'ducks';
import { SnsIcons } from 'components/Sns/SnsIcons';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useQueryString } from 'hooks/useQueryString';
import { useCustomFooterLinks } from 'hooks/useCustomFooterLinks';

import styles from './BookingWidgetFooter.module.css';
import { appendQueryString } from 'lib/util/appendQueryString';

const BookingWidgetFooter = () => {
  const { i18n, t } = useTranslation();

  const { useApiKeyInPaths, apiKey } = React.useContext(ApiKeyContext);

  const queryString = useQueryString();

  const settings = useSelector((state: ReduxState) =>
    toFooterSettingsShape(state.server.settings.all)
  );

  const customFooterLinks = useCustomFooterLinks();

  return (
    <footer className={styles['c-footer']}>
      {customFooterLinks.length > 0 && (
        <div className={styles['c-footer__custom__links']}>
          <ul className={styles['c-footer__custom__links__list']}>
            {customFooterLinks.map((footerLink, idx) => (
              <li key={idx}>
                {footerLink.custom_page_path ? (
                  <Link
                    href={appendQueryString(
                      useApiKeyInPaths
                        ? `/${apiKey}${footerLink.custom_page_path}`
                        : footerLink.custom_page_path,
                      queryString
                    )}
                  >
                    {footerLink.text}
                  </Link>
                ) : (
                  <a href={footerLink.url} target="_blank" rel="noopener noreferrer">
                    {footerLink.text}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={styles['c-footer__section']}>
        <div>
          <div className={styles['c-footer__info']}>
            <div className={styles['c-footer__info__section']}>
              <p>
                <img src="/static/images/ic_phone.svg" alt="Phone" />
                <span>{settings.phone}</span>
              </p>
              <p>
                <img src="/static/images/ic_mail.svg" alt="Mail" />
                <span>{settings.email}</span>
              </p>
            </div>
            <div className={styles['c-footer__info__section']}>
              <p>
                <img src="/static/images/ic_location.svg" alt="Location" />
                <span>{settings.address}</span>
              </p>
              <p>
                <img src="/static/images/ic_time.svg" alt="Time" />
                <span>
                  {t('Hours: {{operatingHoursDescription}}', {
                    operatingHoursDescription: settings.operatingHoursDescription,
                  })}
                </span>
              </p>
            </div>
          </div>
          <SnsIcons snsIds={settings?.snsIds} />
        </div>
      </div>
      <div className={styles['c-footer__rule']}>
        {settings.agentName && (
          <div className={styles['c-footer__rule__logo']}>
            {settings.agentLogoURL ? (
              <img src={settings.agentLogoURL} alt={settings.agentName} />
            ) : (
              settings.agentName
            )}
          </div>
        )}
        {i18n.language === 'ja-JP' && settings.showJapaneseTerms && (
          <ul className={styles['c-footer__rule__list']}>
            <li>
              <a href="https://app.ntmg.com/ja/terms" target="_blank" rel="noopener noreferrer">
                特定商取引法
              </a>
            </li>
          </ul>
        )}
      </div>
      <p className={styles['c-footer__copy']}>© 2022 NutmegLabs Inc.</p>
    </footer>
  );
};

export default BookingWidgetFooter;
